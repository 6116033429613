export const numberFormat = (
  number: number | string | undefined | null,
  option: {
    signed?: boolean;
    percent?: boolean;
    maximumFractionDigits?: number;
  } = {},
): string | undefined => {
  const n = Number(number);
  if (number === null || number === undefined || isNaN(n)) return undefined;

  return new Intl.NumberFormat('ja-JP', {
    signDisplay: option.signed ? 'exceptZero' : 'auto',
    style: option.percent ? 'percent' : 'decimal',
    // 既存の挙動を踏襲し、percent指定時は小数点1桁で四捨五入する挙動を維持。
    // これは maximumFractionDigits 指定で上書き可能
    maximumFractionDigits:
      option.maximumFractionDigits ?? (option.percent ? 0 : 20),
  }).format(n);
};
