import {
  WIDTH_FOR_LEFT_Y_AXIS,
  WIDTH_FOR_RIGHT_Y_AXIS,
} from './components/Chart';
import { ChartWithControlPresenter } from './presenter';

import { useEffect, useRef, useState } from 'react';

import { State } from '../../reducer';
import { ChartData } from '../../types';

type Props = {
  dateUnit: State['dateUnit'];
  setDateUnit: (dateUnit: State['dateUnit']) => void;
  data: ChartData | undefined;
};

const MENU_WIDTH = 190;
const MIN_WINDOW_WIDTH = 1250 + MENU_WIDTH;
const MAIN_CONTENT_HORIZONTAL_PADDING = 80;
const CONTAINER_HORIZONTAL_PADDING = 48;

// 画面の最小サイズで10個のデータを表示する
const WIDTH_PER_COUNT = Math.floor(
  (MIN_WINDOW_WIDTH -
    MENU_WIDTH -
    MAIN_CONTENT_HORIZONTAL_PADDING -
    CONTAINER_HORIZONTAL_PADDING -
    WIDTH_FOR_LEFT_Y_AXIS -
    WIDTH_FOR_RIGHT_Y_AXIS) /
    10,
);

export const ChartWithControl: React.FC<Props> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [displayStartIndex, setDisplayStartIndex] = useState(0);
  const displayCount = ref.current
    ? Math.floor(
        (ref.current.clientWidth -
          CONTAINER_HORIZONTAL_PADDING -
          WIDTH_FOR_LEFT_Y_AXIS -
          WIDTH_FOR_RIGHT_Y_AXIS) /
          WIDTH_PER_COUNT,
      )
    : 1;

  // windowのりサイズなどで表示範囲の終端がデータ数を超えた場合に表示範囲を調整する
  useEffect(() => {
    if (props.data && displayStartIndex + displayCount > props.data.length)
      setDisplayStartIndex(Math.max(props.data.length - displayCount, 0));
  }, [displayCount, props.data]);

  return (
    <div ref={ref}>
      <ChartWithControlPresenter
        {...props}
        setDateUnit={(dateUnit) => {
          setDisplayStartIndex(0);
          props.setDateUnit(dateUnit);
        }}
        displayStartIndex={displayStartIndex}
        incrementDisplayStartIndex={() =>
          setDisplayStartIndex((prev) => prev + 1)
        }
        decrementDisplayStartIndex={() =>
          setDisplayStartIndex((prev) => prev - 1)
        }
        displayCount={displayCount}
      />
    </div>
  );
};
