import { LoadingCircle } from '@/src/components/foundations/Feedback/LoadingCircle';
import { Cluster } from '@/src/components/foundations/Layouts/Cluster';
import { Section } from '@/src/components/foundations/Layouts/Section';
import { Stack } from '@/src/components/foundations/Layouts/Stack';

import { Chart } from './components/Chart';
import { Control } from './components/Control';

import { State } from '../../reducer';
import { ChartData } from '../../types';

type Props = {
  dateUnit: State['dateUnit'];
  setDateUnit: (dateUnit: State['dateUnit']) => void;
  displayStartIndex: number;
  incrementDisplayStartIndex: VoidFunction;
  decrementDisplayStartIndex: VoidFunction;
  displayCount: number;
  data: ChartData | undefined;
};

const CHART_HEIGHT = 456;

export const ChartWithControlPresenter: React.FC<Props> = (props) => (
  <Stack gap={16} align="stretch" width="initial">
    <Control
      disabled={props.data === undefined}
      dateUnit={props.dateUnit}
      setDateUnit={props.setDateUnit}
      displayStartIndex={props.displayStartIndex}
      incrementDisplayStartIndex={props.incrementDisplayStartIndex}
      decrementDisplayStartIndex={props.decrementDisplayStartIndex}
      displayCount={props.displayCount}
      totalCount={props.data?.length ?? 0}
    />
    <Section padding={'0 24px 24px 24px'} width="initial" height={CHART_HEIGHT}>
      {props.data ? (
        <Chart
          dateUnit={props.dateUnit}
          data={props.data.slice(
            props.displayStartIndex,
            props.displayStartIndex + props.displayCount,
          )}
        />
      ) : (
        <Cluster align="center" justify="center" height={'100%'}>
          <LoadingCircle />
        </Cluster>
      )}
    </Section>
  </Stack>
);
